// https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Classes

export default class Model {
    // ---------------------------- GETTERS ---------------------------
    get exists() {
        return (this.id != undefined || this.id != null);
    }
    // ---------------------------- METODOS ---------------------------
    fill(attr) {
        // https://www.zeolearn.com/magazine/objects-object-properties-and-object-copy-in-javascript
        const copy = Object.assign({}, attr);
        Object.assign(this, copy) // asigna los valores a la instancia actual
    }
}