<template>
  <section>
    <!-- <div class="card">
      <header class="card-header">
        <p class="card-header-title">
            Defaults Configuration
        </p>
      </header> -->
      <div class="columns is-vcentered">
        <div class="column is-4">
         <b-field grouped group-multiline>
           <!-- <p class="control">
           <label style="width:100%">Set Default Climb Speed</label>
           </p> -->
          <p class="control">
              <b-switch
                  :value="aircraft.default_climb_speed != null"
                  :disabled="aircraft.default_climb_speed == null"
                  @input="onToggleClimbSwitch"
                  type="is-success">
                  Default Climb Speed
              </b-switch>
          </p>
          <p class="control" style="width:100%">
            <b-select placeholder="" expanded v-model="aircraft.default_climb_speed">
              <option
                v-for="(ID, SPEED) in speedOptions.climb"
                :value="SPEED"
                :key="ID"
                required>
                {{ SPEED }}
              </option>
            </b-select>
          </p>  
          </b-field> 
        </div>
        <div class="column is-4">
         <b-field grouped group-multiline>
           <!-- <p class="control">
           <label style="width:100%">Set Default Climb Speed</label>
           </p> -->
          <p class="control">
              <b-switch
                  :value="aircraft.default_cruise_speed != null"
                  :disabled="aircraft.default_cruise_speed == null"
                  @input="onToggleCruiseSwitch"
                  type="is-success">
                  Default Cruise Speed
              </b-switch>
          </p>
          <p class="control" style="width:100%">
            <b-select placeholder="" expanded v-model="aircraft.default_cruise_speed">
              <option
                v-for="(ID, SPEED) in speedOptions.cruise"
                :value="SPEED"
                :key="ID"
                required>
                {{ SPEED }}
              </option>
            </b-select>
          </p>  
          </b-field> 
        </div>
        <div class="column is-4">
         <b-field grouped group-multiline>
           <!-- <p class="control">
           <label style="width:100%">Set Default Climb Speed</label>
           </p> -->
          <p class="control">
              <b-switch
                  :value="aircraft.default_descent_speed != null"
                  :disabled="aircraft.default_descent_speed == null"
                  @input="onToggleDescentSwitch"
                  type="is-success">
                  Default Descent Speed
              </b-switch>
          </p>
          <p class="control" style="width:100%">
            <b-select placeholder="" expanded v-model="aircraft.default_descent_speed">
              <option
                v-for="(ID, SPEED) in speedOptions.descent"
                :value="SPEED"
                :key="ID"
                required>
                {{ SPEED }}
              </option>
            </b-select>
          </p>  
          </b-field> 
        </div>
      </div> 
    <!-- </div> -->
  </section>
</template>

<script>
import AircraftService from "../../services/aircrafts.service";

export default {
  name: "SpeedsConfigurator",
  props:[
      'aircraft'
  ],
  data() {
    return {
      aircraftService: new AircraftService(),
      speedOptions: []
    };
  },
  computed: {
  },
  methods: {
    onToggleClimbSwitch () {
      if (this.aircraft.default_climb_speed != null) {
        this.aircraft.default_climb_speed = null;
      }
    },
    onToggleCruiseSwitch () {
      if (this.aircraft.default_cruise_speed != null) {
        this.aircraft.default_cruise_speed = null;
      }
    },
    onToggleDescentSwitch () {
      if (this.aircraft.default_descent_speed != null) {
        this.aircraft.default_descent_speed = null;
      }
    }
  },
  watch: {
  },
  mounted: function() {
    this.aircraftService.allSpeedOptions(this.aircraft.id).then(
      ({data}) => {
        this.speedOptions = data.speedOptions;
      }
    );
  },
  components: {
  }
};
</script>
<style>
</style>
