<template>
  <section id="AircraftCreatorSection">
    <vue-headful title="FLPN Aviasolutions - Create Aircraft"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <!-- <h2 class="subtitle">Get user Input. Use with <strong>Field</strong> to access all functionalities</h2> -->
    <div class="columns is-centered">
      <div class="column is-12">
        <div class="card">
          <header class="card-header" v-if="$route.params.aircraftId">
            <p class="card-header-title">
              <span>{{ aircraft_tail_number }}</span>
            </p>
          </header>
          <div class="card-content">
            <form action="#" autocomplete="off" v-on:submit.prevent="save">
              <div class="columns">
                <div class="column">
                  <b-field label="Tail Number">
                    <b-input v-model="aircraft.tail_number" required></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <div class="label">Weight Unit</div>
                  <div class="block">
                    <b-radio v-model="aircraft.weight_unit" native-value="KG">KG</b-radio>
                    <b-radio v-model="aircraft.weight_unit" native-value="LB">LB</b-radio>
                  </div>
                </div>
                <div class="column">
                  <b-field label="ENGINE">
                    <b-input v-model="aircraft.engine" required></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="MM ID">
                    <b-input v-model="aircraft.mmid" required></b-input>
                  </b-field>
                </div>
                <div class="column" v-if="$auth.check(['admin'])">
                  <b-field label="Fleet">
                    <b-select v-model="aircraft.fleet_id" placeholder="Select a Fleet" expanded required>
                      <option v-for="fleet in fleets"
                          :value="fleet.id"
                          :key="fleet.id">
                        {{ fleet.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column" v-if="$auth.check(['admin'])">
                  <b-field label="Client">
                    <b-select v-model="aircraft.client_id" placeholder="Select a Client" expanded required>
                      <option v-for="client in clients"
                          :value="client.id"
                          :key="client.id">
                        {{ client.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fuel Capacity">
                    <b-input type="number" required min="0" v-model="aircraft.mfuel"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="MTW">
                    <b-input type="number" required min="0" v-model="aircraft.mtw"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="MTOW">
                    <b-input type="number" required min="0" v-model="aircraft.mtow"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="MZFW">
                    <b-input type="number" required min="0" v-model="aircraft.mzfw"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="MLW">
                    <b-input type="number" required min="0" v-model="aircraft.mlw"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Max Usable Fuel">
                    <b-input type="max_usable_fuel" required min="0" v-model="aircraft.max_usable_fuel"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fuel Degradation">
                    <b-input required type="number" step="0.01" min="0" max="100" v-model="aircraft.deg"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="aircraft && aircraft.id">
                <div class="column is-12">
                  <SpeedsConfigurator :aircraft="aircraft"></SpeedsConfigurator>
                </div>
              </div>

              <AircraftVersionsManager :versions="aircraft.versions"/>

              <div class="field notification is-default is-grouped is-grouped-right">
                <p class="control">
                  <router-link :to="'/aircrafts'" class="button is-light">
                    <span class="has-text-weight-bold is-uppercase">Back to Aircraft</span>
                  </router-link>
                </p>
                <div class="control" v-if="$auth.check(['admin'])">
                  <button type="button" class="button is-danger" @click="dropAircraft()">
                    <span class="has-text-weight-bold is-uppercase">Delete</span>
                  </button>
                </div>
                <p class="control">
                  <button type="submit" class="button is-primary">
                    <span class="has-text-weight-bold is-uppercase">Save</span>
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AircraftService from "../../services/aircrafts.service.js";
import FleetsService from "../../services/fleets.service";
import ClientsService from "../../services/clients.service";
import Aircraft from '../../models/aircraft/aircraft.model';
import AircraftVersionsManager from './AircraftVersionsManager';
import SpeedsConfigurator from './SpeedsConfigurator';

export default {
  data() {
    return {
      pageTitle: 'Create Aircraft',
      fleetsService: new FleetsService(),
      aircraftsService: new AircraftService(),
      clientsService: new ClientsService(),
      flightPlan: {},
      aircraft: {},
      fleets: [],
      clients: [],
      aircraft_tail_number: '',
      loaded: false
    };
  },
  mounted: function () {
    this.aircraft = new Aircraft({
          mtw: 0,
          mtow: 0,
          mzfw: 0,
          mlw: 0,
          mfuel: 0,
          max_usable_fuel: 0,
          deg: 0
        }
    )
    this.fleetsService.findAll().then(
        ({data}) => {
          this.fleets = data.fleets;
        }
    );
    this.clientsService.findAll().then(
        ({data}) => {
          this.clients = data.data;
        }
    );
    if (this.$route.params.aircraftId) {
      this.pageTitle = 'Edit Aircraft';
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.aircraftsService.edit(this.$route.params.aircraftId).then(response => {
            this.aircraft = response.body.data;
            this.aircraft_tail_number = response.body.data.tail_number;
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: 'An error occurred while trying to load an aircraft.',
              type: 'is-danger'
            });
          });
    }
  },
  components: {
    AircraftVersionsManager,
    SpeedsConfigurator
  },
  methods: {
    save: function () {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.aircraftsService.save(this.aircraft).then(
          ({data}) => {
            loadingComponent.close();
            this.aircraft = data.aircraft;
            this.aircraft_tail_number = data.aircraft.tail_number;
            if (this.aircraft && this.aircraft.id) {
              this.$router.push(`/aircrafts/${this.aircraft.id}/edit`);
              this.pageTitle = 'Edit Aircraft';
            }
            this.$buefy.toast.open({
              message: data.notification,
              type: 'is-success'
            });
          },
          (failed) => {
            loadingComponent.close();
            const messages = failed.statusText
            this.$buefy.toast.open({
              duration: 5000,
              message: messages,
              type: 'is-danger'
            });
          }
      );
    },
    dropAircraft: function () {
      this.aircraftsService.delete(this.aircraft.id).then(() => {
        this.$router.push(`/aircrafts`);
        this.$buefy.toast.open({
          message: 'Aircraft deleted successfully',
          type: 'is-success'
        });
      });
    }
  }
};
</script>
<style>
#AircraftCreatorSection .card-header {
  background-color: lightgrey;
}
</style>