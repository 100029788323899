import ApiService from "./api.service"

export default class AircraftService extends ApiService {
    path = 'aircrafts';
    dataByEngWorkAndGear(aircraftId, params) {
        return this.http.get(`${this.url}/${aircraftId}/data-by-engwork-and-gear`, {params});
    }
    allSpeedOptions(aircraftId) {
        return this.http.get(`${this.url}/${aircraftId}/all-speed-options`);
    }
    configParams(aircraftId) {
        return this.http.get(`${this.url}/${aircraftId}/config-params`);
    }
    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }    
}