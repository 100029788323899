<template>
  <section id="AircraftVersionsManagerSection">
        <div class="columns">
          <div class="column is-12 has-text-centered">
            <p class="title is-12">
                Aircraft Versions &nbsp;&nbsp;
                <b-button
                    type="is-primary"
                    icon-left="plus"
                    rounded style="float:auto;"
                    @click="addVersion()"
                >
                    Add Version
                </b-button>
            </p>
            <h2 class="subtitle"><span style="font-size:0.8em;">Changes won't take effect until you hit the "save" button</span></h2>
          </div>
        </div>
        <div class="columns"
          v-for="(version, index) in versions"
          v-bind:key="version.id"
        >
          <div class="column is-5">
            <b-field horizontal label="Code">
              <b-input type="tel" pattern="[1-9][/][0-9]{1,2}" v-mask="'#/##'" v-model="version.version_code" required></b-input>
            </b-field>
          </div>
          <div class="column is-5">
            <b-field horizontal label="EOW">
              <b-input type="number" min="1" required v-model="version.eow"></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-button
                type="is-danger"
                outlined
                @click="removeVersion(index)"
            >
                Delete
            </b-button>
          </div>
        </div>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: ["versions"],
  directives: { mask },
  data() {
    return {};
  },
  mounted: function() {},
  methods: {
      addVersion() {
          this.versions.unshift({
              id: null,
              version_code: null,
              eow: 0
          })
      },
      removeVersion(index) {
        this.$delete(this.versions, index);
      }
  }
};
</script>
<style>
#AircraftVersionsManagerSection .card-header {
  background-color: lightgrey;
}
</style>