import Model from "../model"

export default class Aircraft extends Model {
    constructor(attr = null) {
        super();
        this.tail_number = "";
        this.motor = "";
        this.mtw = null;
        this.mtow = null;
        this.mzfw = null;
        this.mlw = null;
        this.mfuel = null;
        this.max_usable_fuel = null;
        this.weight_unit = "";
        this.deg = null;
        this.cant_motores = null;
        this.gear = null;
        this.versions = [];
        this.config_params = {};
        this.eow = 0;

        if (attr) {
            this.fill(attr);
        }
    }
    // ---------------------------- GETTERS ---------------------------
    get hasVersions() {
        return this.versions.length > 0;
    }
    get versionDefault() {
        return this.versions.find(version => version.version_code == '2/4');
    }
    // ---------------------------- METODOS ---------------------------
    static createMany(collection) {
        return collection.map(function(item) {
            return new Aircraft(item);
        });
    }
}